import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "./components/Home";
import D18075 from "./components/D18075";
import D18080 from "./components/D18080";
import D21056 from "./components/D21056";


export default function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/D18075" component={D18075} />
        <Route exact path="/D18080" component={D18080} />
        <Route exact path="/D21056" component={D21056} />

      </Switch>
    </BrowserRouter>
  );
}
