import React, { useRef, useState, useMemo } from "react";
import { useCamera, OrthographicCamera, useCursor } from "@react-three/drei";
import {
  useThree,
  useFrame,
  createPortal,
  useLoader,
} from "@react-three/fiber";

import { Scene, Matrix4 } from "three";
import { TextureLoader } from "three/src/loaders/TextureLoader.js";

function ViewCube() {
  const { gl, scene, camera, size } = useThree();
  const virtualScene = useMemo(() => new Scene(), []);
  const virtualCam = useRef();
  const ref = useRef();
  const [hover, set] = useState(null);
  const matrix = new Matrix4();

  const texture_top = useLoader(TextureLoader, "textures/top.png");
  const texture_bottom = useLoader(TextureLoader, "textures/bottom.png");
  const texture_front = useLoader(TextureLoader, "textures/front.png");
  const texture_back = useLoader(TextureLoader, "textures/back.png");
  const texture_right = useLoader(TextureLoader, "textures/right.png");
  const texture_left = useLoader(TextureLoader, "textures/left.png");

  useFrame(() => {
    matrix.copy(camera.matrix).invert();
    ref.current.quaternion.setFromRotationMatrix(matrix);
    gl.autoClear = true;
    gl.render(scene, camera);
    gl.autoClear = false;
    gl.clearDepth();
    gl.render(virtualScene, virtualCam.current);
  }, 1);

  const [active, setActive] = useState(false);
  useCursor(active);

  return createPortal(
    <>
      <OrthographicCamera
        ref={virtualCam}
        makeDefault={false}
        position={[0, 0, 100]}
      />
      <mesh
        onPointerOver={() => setActive(true)}
        onPointerOut={() => setActive(false)}
        ref={ref}
        raycast={useCamera(virtualCam)}
        position={[size.width / 2 - 80, size.height / 2 - 80, 0]}
        onClick={() => {
          console.log("hey");
        }}
        //   onPointerOut={(e) => set(null)}
        //   onPointerMove={(e) => set(Math.floor(e.faceIndex / 2))}
      >
        {[...Array(6)].map((_, index) => (
          <meshLambertMaterial
            attachArray="material"
            key={index}
            // color={index === 0 ? "red" : "white"}
            map={
              index === 0
                ? texture_right
                : index === 1
                ? texture_left
                : index === 2
                ? texture_top
                : index === 3
                ? texture_bottom
                : index === 4
                ? texture_back
                : index === 5
                ? texture_front
                : null
            }
          />
        ))}

        <boxBufferGeometry attach="geometry" args={[80, 80, 80]} />
      </mesh>
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} intensity={0.5} />
    </>,
    virtualScene
  );
}

export default ViewCube;
