import React, { useRef, useEffect, Suspense, useState, useMemo } from "react";
import {
  useGLTF,
  useAnimations,
  Environment,
  OrbitControls,
  Center,
} from "@react-three/drei";
import { Canvas, useFrame } from "@react-three/fiber";
import Loader from "./Loader";
import { LoopOnce, LoopPingPong } from "three";
import { Grid, Typography, Box } from "@mui/material";
import ViewCube from "./ViewCube/ViewCube";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

function ModelExplode(props, { onVerification }) {
  const group = useRef();
  const pecaFechar6Ref = useRef();
  const { nodes, materials, animations } = useGLTF("/D18080.glb");
  const { actions } = useAnimations(animations, group);
  const [animationId, setAnimationId] = useState(0);
  const [faseActive, setFaseActive] = useState(true);
  const [verified, setVerified] = useState(true);

  pecaFechar6Ref.current = actions.pecaFechar6;


  useEffect(() => {
    if (props.idle) {
      playAnimIdle();
    }
  }, [props.idle]);

  useEffect(() => {
    if (props.fase5) {
     // playAnimIdle();
    }
  }, [props.fase5]);

  useEffect(() => {
    if (props.fase1) {
      animationId === 0 ? playAnimFase1() : playAnimFase1Reverse();
    }
  }, [props.fase1]);

  useEffect(() => {
    if (props.fase2) {
      animationId === 1 ? playAnimFase2() : playAnimFase2Reverse();
    }
  }, [props.fase2]);

  useEffect(() => {
    if (props.fase3) {
      playAnimFase3();
    }
  }, [props.fase3]);

  useFrame(() => {
    handleVerify();
  });

    const handleVerify  = () => {
      if (actions.pecaFechar6.isRunning() ||actions.pecaFechar12.isRunning() || actions.pecaFechar18.isRunning() || actions.pecaTirar13.isRunning() || actions.pecaTirar7.isRunning() || actions.pecaTirar1.isRunning()) {
        setVerified(false);
        props.onVerification(false);

        
      } else {
        setVerified(true);
        props.onVerification(true);
      } 
    };

    


  const playAnimIdle = () => {
    setAnimationId(0);
    actions.levantarTudoD.stop();
    actions.levantarTudoE.stop();
    actions.molaLevantarD.stop();
    actions.molaLevantarE.stop();
    actions.molabaixoLevantarD.stop();
    for (let i = 1; i < 7; i++) {
      actions[`pecaFechar${i}`].stop();
    }
    for (let i = 6; i > 0; i--) {
      actions[`pecaTirar${i}`].play();
      actions[`pecaTirar${i}`].clampWhenFinished = true;
      actions[`pecaTirar${i}`].setLoop(LoopOnce);
    }
    actions.fecharTudoE.play();
    actions.fecharTudoE.clampWhenFinished = true;
    actions.fecharTudoE.setLoop(LoopOnce);
    actions.fecharTudoD.play();
    actions.fecharTudoD.clampWhenFinished = true;
    actions.fecharTudoD.setLoop(LoopOnce);
    actions.molaFecharE.play();
    actions.molaFecharE.clampWhenFinished = true;
    actions.molaFecharE.setLoop(LoopOnce);
    actions.molaFecharD.play();
    actions.molaFecharD.clampWhenFinished = true;
    actions.molaFecharD.setLoop(LoopOnce);
    actions.molabaixoFecharD.play();
    actions.molabaixoFecharD.clampWhenFinished = true;
    actions.molabaixoFecharD.setLoop(LoopOnce);


  };

  const playAnimFase1Reverse = () => {
    setAnimationId(1);
    actions.fecharMeioE.stop();
    actions.fecharMeioD.stop();
    actions.fecharMeioE1.stop();
    actions.fecharMeioD1.stop();
    for (let i = 7; i < 13; i++) {
      actions[`pecaFechar${i}`].stop();
    }
    
    for (let i = 12; i > 6; i--) {
      actions[`pecaTirar${i}`].play();
      actions[`pecaTirar${i}`].clampWhenFinished = true;
      actions[`pecaTirar${i}`].setLoop(LoopOnce);
    }
    actions.levantarMeioE.play();
    actions.levantarMeioE.clampWhenFinished = true;
    actions.levantarMeioE.setLoop(LoopOnce);
    actions.levantarMeioD.play();
    actions.levantarMeioD.clampWhenFinished = true;
    actions.levantarMeioD.setLoop(LoopOnce);
    actions.levantarMeioE1.play();
    actions.levantarMeioE1.clampWhenFinished = true;
    actions.levantarMeioE1.setLoop(LoopOnce);
    actions.levantarMeioD1.play();
    actions.levantarMeioD1.clampWhenFinished = true;
    actions.levantarMeioD1.setLoop(LoopOnce);

  };

  const playAnimFase1 = () => {


    actions.fecharTudoD.stop();
    actions.fecharTudoE.stop();
    actions.molaFecharD.stop();
    actions.molaFecharE.stop();
    actions.molabaixoFecharD.stop();

    for (let i = 6; i > 0; i--) {
      actions[`pecaTirar${i}`].stop();
    }

    actions.levantarTudoD.play();
    actions.levantarTudoD.clampWhenFinished = true;
    actions.levantarTudoD.setLoop(LoopOnce);
    actions.molaLevantarD.play();
    actions.molaLevantarD.clampWhenFinished = true;
    actions.molaLevantarD.setLoop(LoopOnce);
    actions.molaLevantarE.play();
    actions.molaLevantarE.clampWhenFinished = true;
    actions.molaLevantarE.setLoop(LoopOnce);
    actions.levantarTudoE.play();
    actions.levantarTudoE.clampWhenFinished = true;
    actions.levantarTudoE.setLoop(LoopOnce);
    actions.molabaixoLevantarD.play();
    actions.molabaixoLevantarD.clampWhenFinished = true;
    actions.molabaixoLevantarD.setLoop(LoopOnce);
    for (let i = 1; i < 7; i++) {
      actions[`pecaFechar${i}`].play();
      actions[`pecaFechar${i}`].clampWhenFinished = true;
      actions[`pecaFechar${i}`].setLoop(LoopOnce);
      actions[`pecaFechar${i}`].timeScale = 1.5;
    }
    setAnimationId(1);
  };

  const playAnimFase2 = () => {

  
    actions.levantarMeioD.stop();
    actions.levantarMeioE.stop();
    actions.levantarMeioD1.stop();
    actions.levantarMeioE1.stop();
    for (let i = 12; i > 6; i--) {
    actions[`pecaTirar${i}`].stop();
  }
    actions.fecharMeioE.play();
    actions.fecharMeioE.clampWhenFinished = true;
    actions.fecharMeioE.setLoop(LoopOnce);
    actions.fecharMeioD.play();
    actions.fecharMeioD.clampWhenFinished = true;
    actions.fecharMeioD.setLoop(LoopOnce);
    actions.fecharMeioE1.play();
    actions.fecharMeioE1.clampWhenFinished = true;
    actions.fecharMeioE1.setLoop(LoopOnce);
    actions.fecharMeioD1.play();
    actions.fecharMeioD1.clampWhenFinished = true;
    actions.fecharMeioD1.setLoop(LoopOnce);
    for (let i = 7; i < 13; i++) {
      actions[`pecaFechar${i}`].play();
      actions[`pecaFechar${i}`].clampWhenFinished = true;
      actions[`pecaFechar${i}`].setLoop(LoopOnce);
    }
    setAnimationId(2);
  };

  const playAnimFase2Reverse = () => {
    setAnimationId(2);
    
    actions.fecharTopE.stop();
    actions.fecharTopD.stop();
    actions.fecharTopE1.stop();
    actions.fecharTopD1.stop();
    for (let i = 13; i < 19; i++) {
      actions[`pecaFechar${i}`].stop();
    }
    
    for (let i = 18; i > 12; i--) {
      actions[`pecaTirar${i}`].play();
      actions[`pecaTirar${i}`].clampWhenFinished = true;
      actions[`pecaTirar${i}`].setLoop(LoopOnce);
    }
    actions.levantarTopE.play();
    actions.levantarTopE.clampWhenFinished = true;
    actions.levantarTopE.setLoop(LoopOnce);
    actions.levantarTopD.play();
    actions.levantarTopD.clampWhenFinished = true;
    actions.levantarTopD.setLoop(LoopOnce);
    actions.levantarTopE1.play();
    actions.levantarTopE1.clampWhenFinished = true;
    actions.levantarTopE1.setLoop(LoopOnce);
    actions.levantarTopD1.play();
    actions.levantarTopD1.clampWhenFinished = true;
    actions.levantarTopD1.setLoop(LoopOnce);
  };

  const playAnimFase3 = () => {

    actions.levantarTopD.stop();
    actions.levantarTopE.stop();
    actions.levantarTopD1.stop();
    actions.levantarTopE1.stop();
    for (let i = 18; i > 12; i--) {
    actions[`pecaTirar${i}`].stop();
    }
    actions.fecharTopE.play();
    actions.fecharTopE.clampWhenFinished = true;
    actions.fecharTopE.setLoop(LoopOnce);
    actions.fecharTopD.play();
    actions.fecharTopD.clampWhenFinished = true;
    actions.fecharTopD.setLoop(LoopOnce);
    actions.fecharTopE1.play();
    actions.fecharTopE1.clampWhenFinished = true;
    actions.fecharTopE1.setLoop(LoopOnce);
    actions.fecharTopD1.play();
    actions.fecharTopD1.clampWhenFinished = true;
    actions.fecharTopD1.setLoop(LoopOnce);
    for (let i = 13; i < 19; i++) {
      actions[`pecaFechar${i}`].play();
      actions[`pecaFechar${i}`].clampWhenFinished = true;
      actions[`pecaFechar${i}`].setLoop(LoopOnce);
      
    }
    
   
  };

  return (


    <Center>
     
      
     <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="bottom" position={[0.02125026, 0.11389543, -0.00369171]}>
          <mesh
            name="imagetostl_mesh0"
            castShadow
            receiveShadow
            geometry={nodes.imagetostl_mesh0.geometry}
            material={materials.Bluesteel}
          />
          <mesh
            name="imagetostl_mesh0_1"
            castShadow
            receiveShadow
            geometry={nodes.imagetostl_mesh0_1.geometry}
            material={materials["Material.002"]}
          />
        </group>
        <mesh
          name="botDireita"
          castShadow
          receiveShadow
          geometry={nodes.botDireita.geometry}
          material={materials.Bluesteel}
          position={[-1.96523595, 0.54691434, 0.000295]}
          rotation={[0, 0, -Math.PI / 2]}
        >
          <group
            name="meioDireito"
            position={[0.01490498, 0.4111487, -0.02500093]}
            rotation={[0, 0, Math.PI / 2]}
          >
            <mesh
              name="imagetostl_mesh0188"
              castShadow
              receiveShadow
              geometry={nodes.imagetostl_mesh0188.geometry}
              material={materials.Bluesteel}
            />
            <mesh
              name="imagetostl_mesh0188_1"
              castShadow
              receiveShadow
              geometry={nodes.imagetostl_mesh0188_1.geometry}
              material={materials.RedSteel}
            />
            <mesh
              name="imagetostl_mesh0188_2"
              castShadow
              receiveShadow
              geometry={nodes.imagetostl_mesh0188_2.geometry}
              material={materials["Material.002"]}
            />
          </group>
          <group
            name="meioDireito1"
            position={[0.01490498, 0.4111487, -0.02500093]}
            rotation={[0, 0, Math.PI / 2]}
          >
            <mesh
              name="imagetostl_mesh0004"
              castShadow
              receiveShadow
              geometry={nodes.imagetostl_mesh0004.geometry}
              material={materials.Bluesteel}
            />
            <mesh
              name="imagetostl_mesh0004_1"
              castShadow
              receiveShadow
              geometry={nodes.imagetostl_mesh0004_1.geometry}
              material={materials.RedSteel}
            />
            <mesh
              name="imagetostl_mesh0004_2"
              castShadow
              receiveShadow
              geometry={nodes.imagetostl_mesh0004_2.geometry}
              material={materials["Material.002"]}
            />
          </group>
          <group
            name="topDireito"
            position={[-0.07187756, 0.86971265, -0.02436651]}
            rotation={[0, 0, Math.PI / 2]}
          >
            <mesh
              name="imagetostl_mesh0230"
              castShadow
              receiveShadow
              geometry={nodes.imagetostl_mesh0230.geometry}
              material={materials.Bluesteel}
            />
            <mesh
              name="imagetostl_mesh0230_1"
              castShadow
              receiveShadow
              geometry={nodes.imagetostl_mesh0230_1.geometry}
              material={materials.RedSteel}
            />
            <mesh
              name="imagetostl_mesh0230_2"
              castShadow
              receiveShadow
              geometry={nodes.imagetostl_mesh0230_2.geometry}
              material={materials["Material.002"]}
            />
          </group>
          <group
            name="topDireito1"
            position={[-0.07187756, 0.86971265, -0.02436651]}
            rotation={[0, 0, Math.PI / 2]}
          >
            <mesh
              name="imagetostl_mesh0003"
              castShadow
              receiveShadow
              geometry={nodes.imagetostl_mesh0003.geometry}
              material={materials.Bluesteel}
            />
            <mesh
              name="imagetostl_mesh0003_1"
              castShadow
              receiveShadow
              geometry={nodes.imagetostl_mesh0003_1.geometry}
              material={materials.RedSteel}
            />
            <mesh
              name="imagetostl_mesh0003_2"
              castShadow
              receiveShadow
              geometry={nodes.imagetostl_mesh0003_2.geometry}
              material={materials["Material.002"]}
            />
          </group>
        </mesh>
        <mesh
          name="botEsquerdo"
          castShadow
          receiveShadow
          geometry={nodes.botEsquerdo.geometry}
          material={materials.Bluesteel}
          position={[1.96351373, 0.75987375, 0.012253]}
          rotation={[0, 0, Math.PI / 2]}
        >
          <group
            name="meioEsquerdo"
            position={[-0.01306175, 0.19915682, 0.01343003]}
            rotation={[0, 0, -Math.PI / 2]}
          >
            <mesh
              name="imagetostl_mesh0174"
              castShadow
              receiveShadow
              geometry={nodes.imagetostl_mesh0174.geometry}
              material={materials.Bluesteel}
            />
            <mesh
              name="imagetostl_mesh0174_1"
              castShadow
              receiveShadow
              geometry={nodes.imagetostl_mesh0174_1.geometry}
              material={materials.RedSteel}
            />
            <mesh
              name="imagetostl_mesh0174_2"
              castShadow
              receiveShadow
              geometry={nodes.imagetostl_mesh0174_2.geometry}
              material={materials["Material.002"]}
            />
          </group>
          <group
            name="meioEsquerdo1"
            position={[-0.01306175, 0.19915682, 0.01343003]}
            rotation={[0, 0, -Math.PI / 2]}
          >
            <mesh
              name="imagetostl_mesh0001"
              castShadow
              receiveShadow
              geometry={nodes.imagetostl_mesh0001.geometry}
              material={materials.Bluesteel}
            />
            <mesh
              name="imagetostl_mesh0001_1"
              castShadow
              receiveShadow
              geometry={nodes.imagetostl_mesh0001_1.geometry}
              material={materials.RedSteel}
            />
            <mesh
              name="imagetostl_mesh0001_2"
              castShadow
              receiveShadow
              geometry={nodes.imagetostl_mesh0001_2.geometry}
              material={materials["Material.002"]}
            />
          </group>
          <group
            name="topEsquerdo"
            position={[0.07712767, 0.66188473, 0.01330918]}
            rotation={[0, 0, -Math.PI / 2]}
          >
            <mesh
              name="imagetostl_mesh0257"
              castShadow
              receiveShadow
              geometry={nodes.imagetostl_mesh0257.geometry}
              material={materials.Bluesteel}
            />
            <mesh
              name="imagetostl_mesh0257_1"
              castShadow
              receiveShadow
              geometry={nodes.imagetostl_mesh0257_1.geometry}
              material={materials.RedSteel}
            />
            <mesh
              name="imagetostl_mesh0257_2"
              castShadow
              receiveShadow
              geometry={nodes.imagetostl_mesh0257_2.geometry}
              material={materials["Material.002"]}
            />
          </group>
          <group
            name="topEsquerdo1"
            position={[0.07712767, 0.66188473, 0.01330918]}
            rotation={[0, 0, -Math.PI / 2]}
          >
            <mesh
              name="imagetostl_mesh0002"
              castShadow
              receiveShadow
              geometry={nodes.imagetostl_mesh0002.geometry}
              material={materials.Bluesteel}
            />
            <mesh
              name="imagetostl_mesh0002_1"
              castShadow
              receiveShadow
              geometry={nodes.imagetostl_mesh0002_1.geometry}
              material={materials.RedSteel}
            />
            <mesh
              name="imagetostl_mesh0002_2"
              castShadow
              receiveShadow
              geometry={nodes.imagetostl_mesh0002_2.geometry}
              material={materials["Material.002"]}
            />
          </group>
        </mesh>
        <mesh
          name="molaBaixoEsquerdo"
          castShadow
          receiveShadow
          geometry={nodes.molaBaixoEsquerdo.geometry}
          material={materials.Metal}
          position={[1.51853645, 0.13944557, 0.03636391]}
          rotation={[0, 0, 0.03943961]}
        />
        <mesh
          name="molaBaixoDireito"
          castShadow
          receiveShadow
          geometry={nodes.molaBaixoDireito.geometry}
          material={materials.Metal}
          position={[-1.30105078, 0.15443759, 0.0363639]}
          rotation={[0, 0, 0.0289568]}
        />
        <mesh
          name="molaCimaDireito"
          castShadow
          receiveShadow
          geometry={nodes.molaCimaDireito.geometry}
          material={materials.Material}
          position={[-1.85422122, 0.5515697, 0.03636384]}
          rotation={[0, 0, 0.03663052]}
        />
        <mesh
          name="molaCimaEsquerdo"
          castShadow
          receiveShadow
          geometry={nodes.molaCimaEsquerdo.geometry}
          material={materials.Material}
          position={[1.86544406, 0.75865614, 0.03636386]}
          rotation={[0, 0, 0.02930925]}
        />
        <mesh
          name="peca13"
          castShadow
          receiveShadow
          geometry={nodes.peca13.geometry}
          material={materials["Material.001"]}
          position={[-0.00016504, 1.89948416, -1.30145895]}
          scale={0}
        />
        <mesh
          name="peca7"
          castShadow
          receiveShadow
          geometry={nodes.peca7.geometry}
          material={materials["Material.001"]}
          position={[-0.00016504, 1.37777317, -1.30145895]}
          scale={0}
        />
        <mesh
          name="peca1"
          castShadow
          receiveShadow
          geometry={nodes.peca1.geometry}
          material={materials["Material.001"]}
          position={[-0.00016504, 0.79592788, -1.30145895]}
          scale={0}
        />
        <mesh
          name="peca14"
          castShadow
          receiveShadow
          geometry={nodes.peca14.geometry}
          material={materials["Material.001"]}
          position={[-0.00016504, 1.89948416, -0.83221978]}
          scale={0}
        />
        <mesh
          name="peca8"
          castShadow
          receiveShadow
          geometry={nodes.peca8.geometry}
          material={materials["Material.001"]}
          position={[-0.00016504, 1.37777627, -0.83221978]}
          scale={0}
        />
        <mesh
          name="peca2"
          castShadow
          receiveShadow
          geometry={nodes.peca2.geometry}
          material={materials["Material.001"]}
          position={[-0.00016504, 0.79592788, -0.83221978]}
          scale={0}
        />
        <mesh
          name="peca15"
          castShadow
          receiveShadow
          geometry={nodes.peca15.geometry}
          material={materials["Material.001"]}
          position={[-0.00016504, 1.89948416, -0.37305993]}
          scale={0}
        />
        <mesh
          name="peca9"
          castShadow
          receiveShadow
          geometry={nodes.peca9.geometry}
          material={materials["Material.001"]}
          position={[-0.00016504, 1.37777627, -0.37305993]}
          scale={0}
        />
        <mesh
          name="peca3"
          castShadow
          receiveShadow
          geometry={nodes.peca3.geometry}
          material={materials["Material.001"]}
          position={[-0.00016504, 0.79592788, -0.37305993]}
          scale={0}
        />
        <mesh
          name="peca12"
          castShadow
          receiveShadow
          geometry={nodes.peca12.geometry}
          material={materials["Material.001"]}
          position={[-0.00016496, 1.37777627, 1.29832327]}
          scale={0}
        />
        <mesh
          name="peca6"
          castShadow
          receiveShadow
          geometry={nodes.peca6.geometry}
          material={materials["Material.001"]}
          position={[-0.00016496, 0.79592788, 1.29832327]}
          scale={0}
        />
        <mesh
          name="peca17"
          castShadow
          receiveShadow
          geometry={nodes.peca17.geometry}
          material={materials["Material.001"]}
          position={[-0.00016503, 1.89948416, 0.8290841]}
          scale={0}
        />
        <mesh
          name="peca11"
          castShadow
          receiveShadow
          geometry={nodes.peca11.geometry}
          material={materials["Material.001"]}
          position={[-0.00016503, 1.37777627, 0.8290841]}
          scale={0}
        />
        <mesh
          name="peca5"
          castShadow
          receiveShadow
          geometry={nodes.peca5.geometry}
          material={materials["Material.001"]}
          position={[-0.00016503, 0.79592788, 0.8290841]}
          scale={0}
        />
        <mesh
          name="peca16"
          castShadow
          receiveShadow
          geometry={nodes.peca16.geometry}
          material={materials["Material.001"]}
          position={[-0.0001651, 1.89948416, 0.36992431]}
          scale={0}
        />
        <mesh
          name="peca10"
          castShadow
          receiveShadow
          geometry={nodes.peca10.geometry}
          material={materials["Material.001"]}
          position={[-0.0001651, 1.37777627, 0.36992431]}
          scale={0}
        />
        <mesh
          name="peca4"
          castShadow
          receiveShadow
          geometry={nodes.peca4.geometry}
          material={materials["Material.001"]}
          position={[-0.0001651, 0.79592788, 0.36992431]}
          scale={0}
        />
        <mesh
          name="peca18"
          castShadow
          receiveShadow
          geometry={nodes.peca18.geometry}
          material={materials["Material.001"]}
          position={[-0.00016503, 1.89948416, 1.30456269]}
          scale={0}
        />
           <ambientLight intensity={0.5} />
         <pointLight position={[10, 10, 10]} intensity={0.5} />
         <pointLight position={[10, -10, -10]} intensity={0.5} />
         <pointLight position={[-10, 10, 10]} intensity={0.5} />
         <pointLight position={[0, 0, 0]} intensity={0.5} />
      </group>
    </group>
    </Center>
  

  );
}

useGLTF.preload("/D18080.glb");

function D18080() {
  const [fakeIdle, setFakeIdle] = useState(true);
  const [idle, setIdle] = useState(false);
  const [fase1, setFase1] = useState(false);
  const [fase2, setFase2] = useState(false);
  const [fase3, setFase3] = useState(false);
  const [fase, setFase] = useState(0);
  const [faseActive, setFaseActive] = useState(true);


  
  const handleVerification = (result) => {
    // Update the verificationResult state based on the result from the child
    setFaseActive(result);
    
    
  };


  const handleButtonClickRight = () => {
    // console.log(result);
     // Perform an action based on the verification result
     if (faseActive) {
     
 
       if (!fase3){changeFases(1);}
       
     } else {
       // Verification failed
       // Do something else here
      
       changeFases(0);
 
     }
   };

   const handleButtonClickLeft = () => {
    // console.log(result);
     // Perform an action based on the verification result
     if (faseActive) {
      
 
       if (!idle){changeFases(-1);}
       
     } else {

      changeFases(0);
 
     }
   };


  const changeFases = (direction) => {
    setFase((prevFase) => {
      const newFase = prevFase + direction;
      

      if (newFase === 0 && direction === -1) {
        setIdle(true);
        setFakeIdle(false);
        setFase1(false);
        setFase2(false);
        setFase3(false);
   
      }
      if (newFase === 1) {
        setFase1(true);
        setFakeIdle(false);
        setIdle(false);
        setFase2(false);
        setFase3(false);
       
 

      } else if (newFase === 2) {
        setFase1(false);
        setFase2(true);
        setIdle(false);
        setFase3(false);

      } else if (newFase === 3) {
        setFase1(false);
        setFase2(false);
        setIdle(false);
        setFase3(true);
      }

      return newFase;
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100vw",
        backgroundColor: "#f8f8f8",
        overflow: "hidden",
      }}
    >
      <div className="topDiv">
        <Typography
          variant="h3"
          sx={{
            fontFamily: "Outfit, sans-serif",
            fontWeight: 700,
            fontSize: "clamp(1.5rem, 4vw, 48px)",
          }}
        >
          Contentor Automóvel
        </Typography>
        <Typography
          variant="body1"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            fontFamily: "Outfit, sans-serif",
            fontWeight: 300,
            fontSize: "clamp(.5rem, 4vw, 14px)",
          }}
        >
          <Box mr={1}>
            <svg
              id="_x30_01-3d_object"
              enableBackground="new 0 0 512 512"
              height="36"
              viewBox="0 0 512 512"
              width="40"
              fill="none"
              stroke="#000"
              strokeWidth={15}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m511.476 468.168c-.198-.451-7.724-17.164-12.541-27.868-1.652-3.67-5.966-5.305-9.636-3.655-3.671 1.652-5.307 5.966-3.656 9.635l3.471 7.711-102.629-59.241c0-3.835.002-137.083-.004-138.191-.021-2.66-1.504-5.024-3.64-6.248l-119.647-69.064v-120.862c3.497 3.992 4.892 9.467 10.559 9.467 5.864 0 9.403-6.696 5.907-11.546-18.644-25.869-18.367-26.511-21.715-27.487-2.868-.834-6.04.136-7.952 2.73l-17.84 24.757c-2.353 3.265-1.613 7.819 1.653 10.171 3.259 2.348 7.816 1.62 10.173-1.653l4.639-6.438v120.862c-128.472 74.163-120.47 69.186-121.831 71.007-2.105 2.815-1.46-8.016-1.461 142.497l-102.235 59.012 3.368-7.483c1.652-3.67.015-7.984-3.655-9.635-3.671-1.651-7.986-.014-9.637 3.655-2.682 5.961-12.36 27.455-12.54 27.867-2 4.51 1.005 9.717 5.95 10.2 32.087 3.231 30.395 3.105 31.086 3.105 3.695 0 6.863-2.8 7.242-6.554.405-4.005-2.513-7.578-6.518-7.983l-7.628-.771 101.854-58.793c126.003 72.318 119.588 70.04 123.292 70.04 2.862 0-6.975 5.154 123.291-70.04l101.897 58.818-7.379.746c-4.005.405-6.923 3.978-6.518 7.983.407 4.027 4.007 6.922 7.984 6.516 32.805-3.374 32.29-2.591 34.87-5.258 1.252-1.294 2.769-4.975 1.426-8.009zm-255.571-274.3 108.715 62.754-108.715 62.754-108.715-62.754zm-116.003 75.375 108.715 62.754v125.509l-108.716-62.754zm123.291 188.262v-125.509l108.715-62.754v125.509z" />
            </svg>
          </Box>
          Arraste o objeto para uma melhor visualização 3D.
        </Typography>
      </div>
      <div className="modelDiv">
        <Canvas camera={{ position: [15, 0, 25] }}>
          <Suspense fallback={<Loader />}>
            <OrbitControls rotateSpeed={0.7} maxDistance={30} minDistance={10}/>
            <ModelExplode
              idle={idle}
              fakeIdle={fakeIdle}
              fase1={fase1}
              fase2={fase2}
              fase3={fase3}
              scale={5}
              onVerification={handleVerification}
            />
            
          </Suspense>
        </Canvas>
      </div>
      <div className="btnBottomDiv">
        <Grid container justifyContent="center">
          <Grid item display="flex" justifyContent="center" alignItems="center">
        
              <ChevronLeftIcon
                className="arrowIcon"
                fontSize="large"
                onClick={handleButtonClickLeft}
                sx={{ marginRight: "2rem" }}
              />
           

            <FiberManualRecordIcon
              sx={{ color: idle || fakeIdle ? "#00629A" : "#00A8E2" }}
              fontSize={idle || fakeIdle ? "medium" : "small"}
            />
            <FiberManualRecordIcon
              sx={{ color: fase1 ? "#00629A" : "#00A8E2" }}
              fontSize={fase1 ? "medium" : "small"}
            />
            <FiberManualRecordIcon
              sx={{ color: fase2 ? "#00629A" : "#00A8E2" }}
              fontSize={fase2 ? "medium" : "small"}
            />
            <FiberManualRecordIcon
              sx={{ color: fase3 ? "#00629A" : "#00A8E2" }}
              fontSize={fase3 ? "medium" : "small"}
            />

              <ChevronRightIcon
                className="arrowIcon"
                fontSize="large"
                //onClick={() => {if (!idle) {changeFases(-1);}}}
                onClick={handleButtonClickRight}
                sx={{ marginLeft: "2rem" }}
              />
            
              
            
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default D18080;
