import React, { useRef, useEffect, Suspense, useState, useMemo } from "react";
import {
  useGLTF,
  useAnimations,
  Environment,
  OrbitControls,
  Center,
} from "@react-three/drei";
import { Canvas, useFrame } from "@react-three/fiber";
import Loader from "./Loader";
import { LoopOnce, LoopPingPong } from "three";
import { Grid, Typography, Box } from "@mui/material";
import ViewCube from "./ViewCube/ViewCube";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

function ModelExplode(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/D18075.glb");
  const { actions } = useAnimations(animations, group);
  const [animationId, setAnimationId] = useState(0);
  const [faseActive, setFaseActive] = useState(true);
  const [verified, setVerified] = useState(true);

  useEffect(() => {
    if (props.idle) {
      playAnimIdle();
    }
  }, [props.idle]);

  useEffect(() => {
    if (props.fase1) {
      animationId === 0 ? playAnimFase1() : playAnimFase1Reverse();
    }
  }, [props.fase1]);

  useEffect(() => {
    if (props.fase2) {
      animationId === 1 ? playAnimFase2() : playAnimFase2Reverse();
    }
  }, [props.fase2]);

  useEffect(() => {
    if (props.fase3) {
      playAnimFase3();
    }
  }, [props.fase3]);

  useFrame(() => {
    handleVerify();
    console.log(actions.ApareceMotor5.isRunning());
  });

    const handleVerify  = () => {
      if (actions.LevantarTodosDireita.isRunning() ||actions.ApareceMotor2.isRunning() || actions.ApareceMotor12.isRunning() || actions.ApareceMotor5.isRunning() || actions.DesapareceMotor.isRunning() || actions.DesapareceMotor6.isRunning() || actions.DesapareceMotor7.isRunning() || actions.FecharTodosDireita.isRunning() || actions.LevantarMeioDireita.isRunning() || actions.LevantarCimaDireita.isRunning() || actions.LevantarMeioDireita.isRunning() || actions.FecharMeioDireita.isRunning() || actions.FecharCimaDireita.isRunning()) {

        setVerified(false);
        props.onVerification(false);

        
      } else {

        setVerified(true);
        props.onVerification(true);
      } 
    };

  useFrame(() => {
    if (actions.ApareceMotor12.paused === true) {
      actions.FecharMeioDireita.play();
      actions.FecharMeioDireita.clampWhenFinished = true;
      actions.FecharMeioDireita.setLoop(LoopOnce);
      actions.FecharMeioEsquerda.play();
      actions.FecharMeioEsquerda.clampWhenFinished = true;
      actions.FecharMeioEsquerda.setLoop(LoopOnce);
     
    }

    if (actions.ApareceMotor6.paused === true) {
      actions.FecharCimaDireita.play();
      actions.FecharCimaDireita.clampWhenFinished = true;
      actions.FecharCimaDireita.setLoop(LoopOnce);
      actions.FecharCimaEsquerda.play();
      actions.FecharCimaEsquerda.clampWhenFinished = true;
      actions.FecharCimaEsquerda.setLoop(LoopOnce);
      
    }
  });

  const playAnimIdle = () => {
    setAnimationId(0);
    actions.LevantarTodosDireita.stop();
    actions.LevantarMolaDireita.stop();
    actions.LevantarMolaEsquerda.stop();
    actions.LevantarTudoEsquerda.stop();
    actions.LevantarInter.stop();
    actions.LevantarMolaCimaDireita.stop();
    actions.LevantarMolaCimaEsquerda.stop();
    actions.FecharTodosDireita.play();
    actions.FecharTodosDireita.clampWhenFinished = true;
    actions.FecharTodosDireita.setLoop(LoopOnce);
    actions["FecharTudo Esquerda"].play();
    actions["FecharTudo Esquerda"].clampWhenFinished = true;
    actions["FecharTudo Esquerda"].setLoop(LoopOnce);
    actions.FecharInter.play();
    actions.FecharInter.clampWhenFinished = true;
    actions.FecharInter.setLoop(LoopOnce);
    actions.FecharMolaEsquerda.play();
    actions.FecharMolaEsquerda.clampWhenFinished = true;
    actions.FecharMolaEsquerda.setLoop(LoopOnce);
    actions.FecharMolaDireita.play();
    actions.FecharMolaDireita.clampWhenFinished = true;
    actions.FecharMolaDireita.setLoop(LoopOnce);
    actions.FecharMolaCimaEsquerda.play();
    actions.FecharMolaCimaEsquerda.clampWhenFinished = true;
    actions.FecharMolaCimaEsquerda.setLoop(LoopOnce);
    actions.FecharMolaCimaDireita.play();
    actions.FecharMolaCimaDireita.clampWhenFinished = true;
    actions.FecharMolaCimaDireita.setLoop(LoopOnce);
  };

  const playAnimFase1Reverse = () => {
    setAnimationId(1);
    actions.ApareceMotor7.stop();
    actions.ApareceMotor8.stop();
    actions.ApareceMotor9.stop();
    actions.ApareceMotor10.stop();
    actions.ApareceMotor11.stop();
    actions.ApareceMotor12.stop();
    actions.FecharMeioDireita.stop();
    actions.FecharMeioEsquerda.stop();

    actions.DesapareceMotor7.play();
    actions.DesapareceMotor7.clampWhenFinished = true;
    actions.DesapareceMotor7.setLoop(LoopOnce);
    actions.DesapareceMotor8.play();
    actions.DesapareceMotor8.clampWhenFinished = true;
    actions.DesapareceMotor8.setLoop(LoopOnce);
    actions.DesapareceMotor9.play();
    actions.DesapareceMotor9.clampWhenFinished = true;
    actions.DesapareceMotor9.setLoop(LoopOnce);
    actions.DesapareceMotor10.play();
    actions.DesapareceMotor10.clampWhenFinished = true;
    actions.DesapareceMotor10.setLoop(LoopOnce);
    actions.DesapareceMotor11.play();
    actions.DesapareceMotor11.clampWhenFinished = true;
    actions.DesapareceMotor11.setLoop(LoopOnce);
    actions.DesapareceMotor12.play();
    actions.DesapareceMotor12.clampWhenFinished = true;
    actions.DesapareceMotor12.setLoop(LoopOnce);

    actions.LevantarMeioDireita.play();
    actions.LevantarMeioDireita.clampWhenFinished = true;
    actions.LevantarMeioDireita.setLoop(LoopOnce);
    actions.LevantarMeioEsquerda.play();
    actions.LevantarMeioEsquerda.clampWhenFinished = true;
    actions.LevantarMeioEsquerda.setLoop(LoopOnce);
  };

  const playAnimFase1 = () => {
    actions.FecharTodosDireita.stop();
    actions["FecharTudo Esquerda"].stop();
    actions.FecharInter.stop();
    actions.FecharMolaEsquerda.stop();
    actions.FecharMolaDireita.stop();
    actions.FecharMolaCimaDireita.stop();
    actions.FecharMolaCimaEsquerda.stop();
    actions.LevantarTodosDireita.play();
    actions.LevantarTodosDireita.clampWhenFinished = true;
    actions.LevantarTodosDireita.setLoop(LoopOnce);
    actions.LevantarMolaDireita.play();
    actions.LevantarMolaDireita.clampWhenFinished = true;
    actions.LevantarMolaDireita.setLoop(LoopOnce);
    actions.LevantarMolaEsquerda.play();
    actions.LevantarMolaEsquerda.clampWhenFinished = true;
    actions.LevantarMolaEsquerda.setLoop(LoopOnce);
    actions.LevantarTudoEsquerda.play();
    actions.LevantarTudoEsquerda.clampWhenFinished = true;
    actions.LevantarTudoEsquerda.setLoop(LoopOnce);
    actions.LevantarInter.play();
    actions.LevantarInter.clampWhenFinished = true;
    actions.LevantarInter.setLoop(LoopOnce);
    actions.LevantarMolaCimaDireita.play();
    actions.LevantarMolaCimaDireita.clampWhenFinished = true;
    actions.LevantarMolaCimaDireita.setLoop(LoopOnce);
    actions.LevantarMolaCimaEsquerda.play();
    actions.LevantarMolaCimaEsquerda.clampWhenFinished = true;
    actions.LevantarMolaCimaEsquerda.setLoop(LoopOnce);
    setAnimationId(1);
  };

  const playAnimFase2 = () => {
    actions.LevantarMeioDireita.stop();
    actions.LevantarMeioEsquerda.stop();
    actions.DesapareceMotor7.stop();
    actions.DesapareceMotor8.stop();
    actions.DesapareceMotor9.stop();
    actions.DesapareceMotor10.stop();
    actions.DesapareceMotor11.stop();
    actions.DesapareceMotor12.stop();
    actions.ApareceMotor7.play();
    actions.ApareceMotor7.clampWhenFinished = true;
    actions.ApareceMotor7.setLoop(LoopOnce);
    actions.ApareceMotor8.play();
    actions.ApareceMotor8.clampWhenFinished = true;
    actions.ApareceMotor8.setLoop(LoopOnce);
    actions.ApareceMotor9.play();
    actions.ApareceMotor9.clampWhenFinished = true;
    actions.ApareceMotor9.setLoop(LoopOnce);
    actions.ApareceMotor10.play();
    actions.ApareceMotor10.clampWhenFinished = true;
    actions.ApareceMotor10.setLoop(LoopOnce);
    actions.ApareceMotor11.play();
    actions.ApareceMotor11.clampWhenFinished = true;
    actions.ApareceMotor11.setLoop(LoopOnce);
    actions.ApareceMotor12.play();
    actions.ApareceMotor12.clampWhenFinished = true;
    actions.ApareceMotor12.setLoop(LoopOnce);
    setAnimationId(2);
  };

  const playAnimFase2Reverse = () => {
    setAnimationId(2);

    actions.ApareceMotor.stop();
    for (let i = 2; i < 7; i++) {
      actions[`ApareceMotor${i}`].stop();
    }
    actions.FecharCimaDireita.stop();
    actions.FecharCimaEsquerda.stop();
    actions.LevantarCimaDireita.play();
    actions.LevantarCimaDireita.clampWhenFinished = true;
    actions.LevantarCimaDireita.setLoop(LoopOnce);
    actions.LevantarCimaEsquerda.play();
    actions.LevantarCimaEsquerda.clampWhenFinished = true;
    actions.LevantarCimaEsquerda.setLoop(LoopOnce);
    actions.DesapareceMotor.play();
    actions.DesapareceMotor.clampWhenFinished = true;
    actions.DesapareceMotor.setLoop(LoopOnce);
    for (let i = 2; i < 7; i++) {
      actions[`DesapareceMotor${i}`].play();
      actions[`DesapareceMotor${i}`].clampWhenFinished = true;
      actions[`DesapareceMotor${i}`].setLoop(LoopOnce);
    }
  };

  const playAnimFase3 = () => {
    actions.LevantarMeioDireita.stop();
    actions.LevantarMeioEsquerda.stop();
    actions.LevantarCimaDireita.stop();
    actions.LevantarCimaEsquerda.stop();
    actions.DesapareceMotor.stop();
    for (let i = 2; i < 7; i++) {
      actions[`DesapareceMotor${i}`].stop();
    }
    actions.ApareceMotor.play();
    actions.ApareceMotor.clampWhenFinished = true;
    actions.ApareceMotor.setLoop(LoopOnce);
    for (let i = 2; i < 7; i++) {
      actions[`ApareceMotor${i}`].play();
      actions[`ApareceMotor${i}`].clampWhenFinished = true;
      actions[`ApareceMotor${i}`].setLoop(LoopOnce);
    }
    setAnimationId(3);
  };

  return (
    <Center>
      <group ref={group} {...props} dispose={null}>
        <group name="Scene">
          <group
            name="MolaBaseDireita"
            position={[8.81036377, -1.61548507, 0.06426668]}
            rotation={[0, 0, 1.20141918]}
            scale={0.08520013}
          >
            <mesh
              name="Cube"
              castShadow
              receiveShadow
              geometry={nodes.Cube.geometry}
              material={materials.Brushed_Steel_shkafchc}
            />
            <mesh
              name="Cube_1"
              castShadow
              receiveShadow
              geometry={nodes.Cube_1.geometry}
              material={materials.Brushed_Steel_shkafchc}
            />
            <mesh
              name="Cube_2"
              castShadow
              receiveShadow
              geometry={nodes.Cube_2.geometry}
              material={materials.Brushed_Steel_shkafchc}
            />
          </group>
          <group
            name="LeverDireitaTodos"
            position={[9.01726627, -0.02383697, 0.1728797]}
            rotation={[0, 0, 1.55776638]}
            scale={0.12076789}
          >
            <mesh
              name="Cube002"
              castShadow
              receiveShadow
              geometry={nodes.Cube002.geometry}
              material={materials.Hot_Blued_shlaednc}
            />
            <mesh
              name="Cube002_1"
              castShadow
              receiveShadow
              geometry={nodes.Cube002_1.geometry}
              material={materials.Hot_Blued_shlaednc}
            />
            <mesh
              name="Cube002_2"
              castShadow
              receiveShadow
              geometry={nodes.Cube002_2.geometry}
              material={materials.Brushed_Steel_shkafchc}
            />
            <mesh
              name="Cube002_3"
              castShadow
              receiveShadow
              geometry={nodes.Cube002_3.geometry}
              material={materials.Brushed_Steel_shkafchc}
            />
            <mesh
              name="Cube002_4"
              castShadow
              receiveShadow
              geometry={nodes.Cube002_4.geometry}
              material={materials.wire_105015054}
            />
            <mesh
              name="Cube002_5"
              castShadow
              receiveShadow
              geometry={nodes.Cube002_5.geometry}
              material={materials["RED.001"]}
            />
            <mesh
              name="Cube002_6"
              castShadow
              receiveShadow
              geometry={nodes.Cube002_6.geometry}
              material={materials.Material__32}
            />
            <mesh
              name="Cube002_7"
              castShadow
              receiveShadow
              geometry={nodes.Cube002_7.geometry}
              material={materials["Material__28.001"]}
            />
            <mesh
              name="Cube002_8"
              castShadow
              receiveShadow
              geometry={nodes.Cube002_8.geometry}
              material={nodes.Cube002_8.material}
            />
            <group
              name="LeverDireitaCima"
              position={[2.03478384, 35.04170227, 2.30103922]}
              rotation={[0, 0, -1.5187503]}
              scale={[8.28033352, 8.28033352, 8.28033543]}
            >
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B008"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B008"
                  ].geometry
                }
                material={materials.Hot_Blued_shlaednc}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B008_1"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B008_1"
                  ].geometry
                }
                material={materials.rubber2}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B008_2"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B008_2"
                  ].geometry
                }
                material={materials.Empty_material}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B008_3"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B008_3"
                  ].geometry
                }
                material={materials.Material__58}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B008_4"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B008_4"
                  ].geometry
                }
                material={materials.BLUE}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B008_5"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B008_5"
                  ].geometry
                }
                material={materials.Material__70}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B008_6"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B008_6"
                  ].geometry
                }
                material={materials.RED}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B008_7"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B008_7"
                  ].geometry
                }
                material={materials.wire_105015054}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B008_8"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B008_8"
                  ].geometry
                }
                material={materials.RED}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B008_9"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B008_9"
                  ].geometry
                }
                material={materials.rubber2}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B008_10"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B008_10"
                  ].geometry
                }
                material={materials["RED.001"]}
              />
            </group>
            <group
              name="LeverDireitaMeio"
              position={[2.0346117, 17.20325279, 2.0205307]}
              rotation={[0, 0, -1.55520592]}
              scale={[8.28034496, 8.28034496, 8.28033543]}
            >
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B011"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B011"
                  ].geometry
                }
                material={materials.RED}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B011_1"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B011_1"
                  ].geometry
                }
                material={materials.Material__70}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B011_2"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B011_2"
                  ].geometry
                }
                material={materials.Material__54}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B011_3"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B011_3"
                  ].geometry
                }
                material={materials.Material__32}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B011_4"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B011_4"
                  ].geometry
                }
                material={materials.wire_105015054}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B011_5"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B011_5"
                  ].geometry
                }
                material={materials.BLUE}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B011_6"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B011_6"
                  ].geometry
                }
                material={materials.RED}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B011_7"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B011_7"
                  ].geometry
                }
                material={materials.Material__58}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B011_8"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B011_8"
                  ].geometry
                }
                material={materials.Material__59}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B011_9"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B011_9"
                  ].geometry
                }
                material={materials["RED.001"]}
              />
            </group>
            <mesh
              name="MolaCimaDireita"
              castShadow
              receiveShadow
              geometry={nodes.MolaCimaDireita.geometry}
              material={materials.Rubber}
              position={[-5.80053473, 30.06412506, -0.44753021]}
              rotation={[0, 0, -0.35131631]}
              scale={[0.70548654, 0.70548654, 0.70548517]}
            />
          </group>
          <group
            name="MolaBaseEsquerda"
            position={[-8.66052437, -2.81076264, 0.68751621]}
            rotation={[0, 0, -1.21008765]}
            scale={0.04100281}
          >
            <mesh
              name="Cube003"
              castShadow
              receiveShadow
              geometry={nodes.Cube003.geometry}
              material={materials.Brushed_Steel_shkafchc}
            />
            <mesh
              name="Cube003_1"
              castShadow
              receiveShadow
              geometry={nodes.Cube003_1.geometry}
              material={materials.Brushed_Steel_shkafchc}
            />
            <mesh
              name="Cube003_2"
              castShadow
              receiveShadow
              geometry={nodes.Cube003_2.geometry}
              material={materials.Brushed_Steel_shkafchc}
            />
          </group>
          <group
            name="LeverEsquerdaTodos"
            position={[-8.86747074, -1.21259511, 0.59630489]}
            rotation={[0, 0, -1.6077181]}
            scale={[0.02475757, 0.02475757, 0.02475756]}
          >
            <mesh
              name="Cube010"
              castShadow
              receiveShadow
              geometry={nodes.Cube010.geometry}
              material={materials.Hot_Blued_shlaednc}
            />
            <mesh
              name="Cube010_1"
              castShadow
              receiveShadow
              geometry={nodes.Cube010_1.geometry}
              material={materials.Hot_Blued_shlaednc}
            />
            <mesh
              name="Cube010_2"
              castShadow
              receiveShadow
              geometry={nodes.Cube010_2.geometry}
              material={materials.Brushed_Steel_shkafchc}
            />
            <mesh
              name="Cube010_3"
              castShadow
              receiveShadow
              geometry={nodes.Cube010_3.geometry}
              material={materials.Brushed_Steel_shkafchc}
            />
            <mesh
              name="Cube010_4"
              castShadow
              receiveShadow
              geometry={nodes.Cube010_4.geometry}
              material={materials.wire_105015054}
            />
            <mesh
              name="Cube010_5"
              castShadow
              receiveShadow
              geometry={nodes.Cube010_5.geometry}
              material={materials.Brushed_Steel_shkafchc}
            />
            <mesh
              name="Cube010_6"
              castShadow
              receiveShadow
              geometry={nodes.Cube010_6.geometry}
              material={materials.Brushed_Steel_shkafchc}
            />
            <mesh
              name="Cube010_7"
              castShadow
              receiveShadow
              geometry={nodes.Cube010_7.geometry}
              material={materials.Brushed_Steel_shkafchc}
            />
            <mesh
              name="Cube010_8"
              castShadow
              receiveShadow
              geometry={nodes.Cube010_8.geometry}
              material={materials["RED.001"]}
            />
            <mesh
              name="Cube010_9"
              castShadow
              receiveShadow
              geometry={nodes.Cube010_9.geometry}
              material={materials.Brushed_Steel_shkafchc}
            />
            <mesh
              name="Cube010_10"
              castShadow
              receiveShadow
              geometry={nodes.Cube010_10.geometry}
              material={materials.Brushed_Steel_shkafchc}
            />
            <mesh
              name="Cube010_11"
              castShadow
              receiveShadow
              geometry={nodes.Cube010_11.geometry}
              material={nodes.Cube010_11.material}
            />
            <group
              name="LeverEsquerdaCima"
              position={[-18.55016136, 219.10124207, -8.05388451]}
              rotation={[0, 0, 1.59387555]}
              scale={[40.39170074, 40.39170074, 40.39169312]}
            >
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B007"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B007"
                  ].geometry
                }
                material={materials.Hot_Blued_shlaednc}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B007_1"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B007_1"
                  ].geometry
                }
                material={materials.Empty_material}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B007_2"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B007_2"
                  ].geometry
                }
                material={materials.Material__58}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B007_3"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B007_3"
                  ].geometry
                }
                material={materials.Material__59}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B007_4"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B007_4"
                  ].geometry
                }
                material={materials.Material__70}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B007_5"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B007_5"
                  ].geometry
                }
                material={materials.RED}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B007_6"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B007_6"
                  ].geometry
                }
                material={materials.Rubber}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B007_7"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B007_7"
                  ].geometry
                }
                material={materials.wire_105015054}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B007_8"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B007_8"
                  ].geometry
                }
                material={materials.RED}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B007_9"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B007_9"
                  ].geometry
                }
                material={materials["RED.001"]}
              />
            </group>
            <group
              name="LeverEsquerdaMeio"
              position={[-15.04406929, 131.69132996, -9.82367039]}
              rotation={[0, 0, 1.60586745]}
              scale={[40.39163208, 40.39163208, 40.39163589]}
            >
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B009"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B009"
                  ].geometry
                }
                material={materials.BLUE}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B009_1"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B009_1"
                  ].geometry
                }
                material={materials.Material__70}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B009_2"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B009_2"
                  ].geometry
                }
                material={materials.Material__58}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B009_3"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B009_3"
                  ].geometry
                }
                material={materials.Hot_Blued_shlaednc}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B009_4"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B009_4"
                  ].geometry
                }
                material={materials.Hot_Blued_shlaednc}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B009_5"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B009_5"
                  ].geometry
                }
                material={materials.Material__54}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B009_6"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B009_6"
                  ].geometry
                }
                material={materials.Material__32}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B009_7"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B009_7"
                  ].geometry
                }
                material={materials.wire_128128128}
              />
              <mesh
                name="22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B009_8"
                castShadow
                receiveShadow
                geometry={
                  nodes[
                    "22DCF415DADD08D5C1851530BC226BF84BE43A5B___B8A4396250C5687B009_8"
                  ].geometry
                }
                material={materials.BLUE}
              />
            </group>
            <mesh
              name="MolaCimaEsquerda"
              castShadow
              receiveShadow
              geometry={nodes.MolaCimaEsquerda.geometry}
              material={materials.Rubber}
              position={[25.15806007, 146.6394043, 5.27443933]}
              rotation={[0, 0, 0.39080931]}
              scale={[1.65617275, 1.65617275, 1.65617287]}
            />
          </group>
          <mesh
            name="SuporteCaixa"
            castShadow
            receiveShadow
            geometry={nodes.SuporteCaixa.geometry}
            material={materials.BLUE}
            position={[0.07782936, -3.63875532, -5.37710476]}
          />
          <group
            name="BaseCaixa"
            position={[0.07782936, -3.63875532, -5.37710476]}
          >
            <mesh
              name="10C89140D25237E02F5D3BBF78F7B7D70C5A5E5E___B8A4396250C5687B005"
              castShadow
              receiveShadow
              geometry={
                nodes[
                  "10C89140D25237E02F5D3BBF78F7B7D70C5A5E5E___B8A4396250C5687B005"
                ].geometry
              }
              material={materials.wire_105015054}
            />
            <mesh
              name="10C89140D25237E02F5D3BBF78F7B7D70C5A5E5E___B8A4396250C5687B005_1"
              castShadow
              receiveShadow
              geometry={
                nodes[
                  "10C89140D25237E02F5D3BBF78F7B7D70C5A5E5E___B8A4396250C5687B005_1"
                ].geometry
              }
              material={materials.Brushed_Steel_shkafchc}
            />
            <mesh
              name="10C89140D25237E02F5D3BBF78F7B7D70C5A5E5E___B8A4396250C5687B005_2"
              castShadow
              receiveShadow
              geometry={
                nodes[
                  "10C89140D25237E02F5D3BBF78F7B7D70C5A5E5E___B8A4396250C5687B005_2"
                ].geometry
              }
              material={materials.Hot_Blued_shlaednc}
            />
            <mesh
              name="10C89140D25237E02F5D3BBF78F7B7D70C5A5E5E___B8A4396250C5687B005_3"
              castShadow
              receiveShadow
              geometry={
                nodes[
                  "10C89140D25237E02F5D3BBF78F7B7D70C5A5E5E___B8A4396250C5687B005_3"
                ].geometry
              }
              material={materials.Material__70}
            />
            <mesh
              name="10C89140D25237E02F5D3BBF78F7B7D70C5A5E5E___B8A4396250C5687B005_4"
              castShadow
              receiveShadow
              geometry={
                nodes[
                  "10C89140D25237E02F5D3BBF78F7B7D70C5A5E5E___B8A4396250C5687B005_4"
                ].geometry
              }
              material={materials.Brushed_Steel_shkafchc}
            />
            <mesh
              name="10C89140D25237E02F5D3BBF78F7B7D70C5A5E5E___B8A4396250C5687B005_5"
              castShadow
              receiveShadow
              geometry={
                nodes[
                  "10C89140D25237E02F5D3BBF78F7B7D70C5A5E5E___B8A4396250C5687B005_5"
                ].geometry
              }
              material={materials.Brushed_Steel_shkafchc}
            />
            <mesh
              name="10C89140D25237E02F5D3BBF78F7B7D70C5A5E5E___B8A4396250C5687B005_6"
              castShadow
              receiveShadow
              geometry={
                nodes[
                  "10C89140D25237E02F5D3BBF78F7B7D70C5A5E5E___B8A4396250C5687B005_6"
                ].geometry
              }
              material={materials.Hot_Blued_shlaednc}
            />
            <mesh
              name="10C89140D25237E02F5D3BBF78F7B7D70C5A5E5E___B8A4396250C5687B005_7"
              castShadow
              receiveShadow
              geometry={
                nodes[
                  "10C89140D25237E02F5D3BBF78F7B7D70C5A5E5E___B8A4396250C5687B005_7"
                ].geometry
              }
              material={materials.Brushed_Steel_shkafchc}
            />
          </group>
          <group
            name="LeverIntermedia"
            position={[1.48619998, -3.30369949, 0.03068161]}
            rotation={[0, 0, 0.00762484]}
          >
            <mesh
              name="Plane002"
              castShadow
              receiveShadow
              geometry={nodes.Plane002.geometry}
              material={materials.Hot_Blued_shlaednc}
            />
            <mesh
              name="Plane002_1"
              castShadow
              receiveShadow
              geometry={nodes.Plane002_1.geometry}
              material={materials.Material__32}
            />
            <mesh
              name="Plane002_2"
              castShadow
              receiveShadow
              geometry={nodes.Plane002_2.geometry}
              material={materials.Hot_Blued_shlaednc}
            />
            <mesh
              name="Plane002_3"
              castShadow
              receiveShadow
              geometry={nodes.Plane002_3.geometry}
              material={materials.Material__54}
            />
            <mesh
              name="Plane002_4"
              castShadow
              receiveShadow
              geometry={nodes.Plane002_4.geometry}
              material={materials.Material__70}
            />
            <mesh
              name="Plane002_5"
              castShadow
              receiveShadow
              geometry={nodes.Plane002_5.geometry}
              material={materials.Material__58}
            />
          </group>
          <mesh
            name="motor_1"
            castShadow
            receiveShadow
            geometry={nodes.motor_1.geometry}
            material={materials["blackmetal.001"]}
            position={[-5.87092113, 5.20448399, 0.4050464]}
            scale={0}
          />
          <mesh
            name="motor_2"
            castShadow
            receiveShadow
            geometry={nodes.motor_2.geometry}
            material={materials["blackmetal.001"]}
            position={[-6.01716089, 5.20448017, 0.42399088]}
            scale={0}
          />
          <mesh
            name="motor_3"
            castShadow
            receiveShadow
            geometry={nodes.motor_3.geometry}
            material={materials["blackmetal.001"]}
            position={[-0.34591007, 5.20448017, 0.42399088]}
            scale={0}
          />
          <mesh
            name="motor_4"
            castShadow
            receiveShadow
            geometry={nodes.motor_4.geometry}
            material={materials["blackmetal.001"]}
            position={[-0.04605198, 5.20448017, 0.42399088]}
            scale={0}
          />
          <mesh
            name="motor_5"
            castShadow
            receiveShadow
            geometry={nodes.motor_5.geometry}
            material={materials["blackmetal.001"]}
            position={[5.78410006, 5.20448017, 0.42399088]}
            scale={0}
          />
          <mesh
            name="motor_6"
            castShadow
            receiveShadow
            geometry={nodes.motor_6.geometry}
            material={materials["blackmetal.001"]}
            position={[6.02065039, 5.20448017, 0.42399088]}
            scale={0}
          />
          <mesh
            name="motor_7"
            castShadow
            receiveShadow
            geometry={nodes.motor_7.geometry}
            material={materials["blackmetal.001"]}
            position={[-5.87092113, 2.20000005, 0.4050464]}
            scale={0}
          />
          <mesh
            name="motor_8"
            castShadow
            receiveShadow
            geometry={nodes.motor_8.geometry}
            material={materials["blackmetal.001"]}
            position={[-5.87092113, 2.20000005, 0.4050464]}
            scale={0}
          />
          <mesh
            name="motor_9"
            castShadow
            receiveShadow
            geometry={nodes.motor_9.geometry}
            material={materials["blackmetal.001"]}
            position={[0.16769695, 2.20000005, 0.4050464]}
            scale={0}
          />
          <mesh
            name="motor_10"
            castShadow
            receiveShadow
            geometry={nodes.motor_10.geometry}
            material={materials["blackmetal.001"]}
            position={[0.16769695, 2.20000005, 0.4050464]}
            scale={0}
          />
          <mesh
            name="motor_11"
            castShadow
            receiveShadow
            geometry={nodes.motor_11.geometry}
            material={materials["blackmetal.001"]}
            position={[5.7122798, 2.20000005, 0.4050464]}
            scale={0}
          />
          <mesh
            name="motor_12"
            castShadow
            receiveShadow
            geometry={nodes.motor_12.geometry}
            material={materials["blackmetal.001"]}
            position={[5.80094004, 2.20000005, 0.4050464]}
            scale={0}
          />
               <ambientLight intensity={0.5} />
         <pointLight position={[10, 10, 10]} intensity={0.5} />
         <pointLight position={[10, -10, -10]} intensity={0.5} />
         <pointLight position={[-10, 10, 10]} intensity={0.5} />
         <pointLight position={[0, 0, 0]} intensity={0.5} />
        </group>
      </group>
    </Center>
  );
}

useGLTF.preload("/D18075.glb");

function D18075() {
  const [fakeIdle, setFakeIdle] = useState(true);
  const [idle, setIdle] = useState(false);
  const [fase1, setFase1] = useState(false);
  const [fase2, setFase2] = useState(false);
  const [fase3, setFase3] = useState(false);
  const [fase, setFase] = useState(0);
  const [faseActive, setFaseActive] = useState(true);

  const handleVerification = (result) => {
    // Update the verificationResult state based on the result from the child
    setFaseActive(result);
    
    
  };


  const handleButtonClickRight = () => {
    // console.log(result);
     // Perform an action based on the verification result
     if (faseActive) {
     
 
       if (!fase3){changeFases(1);}
       
     } else {
       // Verification failed
       // Do something else here
      
       changeFases(0);
 
     }
   };

   const handleButtonClickLeft = () => {
    // console.log(result);
     // Perform an action based on the verification result
     if (faseActive) {
      
 
       if (!idle){changeFases(-1);}
       
     } else {

      changeFases(0);
 
     }
   };

  const changeFases = (direction) => {
    setFase((prevFase) => {
      const newFase = prevFase + direction;

      if (newFase === 0 && direction === -1) {
        setIdle(true);
        setFakeIdle(false);
        setFase1(false);
        setFase2(false);
        setFase3(false);
      }
      if (newFase === 1) {
        setFase1(true);
        setFakeIdle(false);
        setIdle(false);
        setFase2(false);
        setFase3(false);
      } else if (newFase === 2) {
        setFase1(false);
        setFase2(true);
        setIdle(false);
        setFase3(false);
      } else if (newFase === 3) {
        setFase1(false);
        setFase2(false);
        setIdle(false);
        setFase3(true);
      }

      return newFase;
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100vw",
        backgroundColor: "#f8f8f8",
        overflow: "hidden",
      }}
    >
      <div className="topDiv">
        <Typography
          variant="h3"
          sx={{
            fontFamily: "Outfit, sans-serif",
            fontWeight: 700,
            fontSize: "clamp(1.5rem, 4vw, 48px)",
          }}
        >
          Contentor Automóvel
        </Typography>
        <Typography
          variant="body1"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            fontFamily: "Outfit, sans-serif",
            fontWeight: 300,
            fontSize: "clamp(.5rem, 4vw, 14px)",
          }}
        >
          <Box mr={1}>
            <svg
              id="_x30_01-3d_object"
              enableBackground="new 0 0 512 512"
              height="36"
              viewBox="0 0 512 512"
              width="40"
              fill="none"
              stroke="#000"
              strokeWidth={15}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m511.476 468.168c-.198-.451-7.724-17.164-12.541-27.868-1.652-3.67-5.966-5.305-9.636-3.655-3.671 1.652-5.307 5.966-3.656 9.635l3.471 7.711-102.629-59.241c0-3.835.002-137.083-.004-138.191-.021-2.66-1.504-5.024-3.64-6.248l-119.647-69.064v-120.862c3.497 3.992 4.892 9.467 10.559 9.467 5.864 0 9.403-6.696 5.907-11.546-18.644-25.869-18.367-26.511-21.715-27.487-2.868-.834-6.04.136-7.952 2.73l-17.84 24.757c-2.353 3.265-1.613 7.819 1.653 10.171 3.259 2.348 7.816 1.62 10.173-1.653l4.639-6.438v120.862c-128.472 74.163-120.47 69.186-121.831 71.007-2.105 2.815-1.46-8.016-1.461 142.497l-102.235 59.012 3.368-7.483c1.652-3.67.015-7.984-3.655-9.635-3.671-1.651-7.986-.014-9.637 3.655-2.682 5.961-12.36 27.455-12.54 27.867-2 4.51 1.005 9.717 5.95 10.2 32.087 3.231 30.395 3.105 31.086 3.105 3.695 0 6.863-2.8 7.242-6.554.405-4.005-2.513-7.578-6.518-7.983l-7.628-.771 101.854-58.793c126.003 72.318 119.588 70.04 123.292 70.04 2.862 0-6.975 5.154 123.291-70.04l101.897 58.818-7.379.746c-4.005.405-6.923 3.978-6.518 7.983.407 4.027 4.007 6.922 7.984 6.516 32.805-3.374 32.29-2.591 34.87-5.258 1.252-1.294 2.769-4.975 1.426-8.009zm-255.571-274.3 108.715 62.754-108.715 62.754-108.715-62.754zm-116.003 75.375 108.715 62.754v125.509l-108.716-62.754zm123.291 188.262v-125.509l108.715-62.754v125.509z" />
            </svg>
          </Box>
          Arraste o objeto para uma melhor visualização 3D.
        </Typography>
      </div>
      <div className="modelDiv">
        <Canvas camera={{ position: [15, 0, 25] }}>
          <Suspense fallback={<Loader />}>
            <OrbitControls rotateSpeed={0.7} maxDistance={30} minDistance={10}/>
            <ModelExplode
              idle={idle}
              fakeIdle={fakeIdle}
              fase1={fase1}
              fase2={fase2}
              fase3={fase3}
              onVerification={handleVerification}
            />
   
          </Suspense>
        </Canvas>
      </div>
      <div className="btnBottomDiv">
        <Grid container justifyContent="center">
          <Grid item display="flex" justifyContent="center" alignItems="center">
            <ChevronLeftIcon className="arrowIcon" fontSize="large" onClick={handleButtonClickLeft} sx={{marginRight: "2rem"}} />
            <FiberManualRecordIcon
              sx={{ color: idle || fakeIdle ? "#00629A" : "#00A8E2" }}
              fontSize={idle || fakeIdle ? "medium" : "small"}
            />
            <FiberManualRecordIcon
              sx={{ color: fase1 ? "#00629A" : "#00A8E2" }}
              fontSize={fase1 ? "medium" : "small"}
            />
            <FiberManualRecordIcon
              sx={{ color: fase2 ? "#00629A" : "#00A8E2" }}
              fontSize={fase2 ? "medium" : "small"}
            />
            <FiberManualRecordIcon
              sx={{ color: fase3 ? "#00629A" : "#00A8E2" }}
              fontSize={fase3 ? "medium" : "small"}
            />
            <ChevronRightIcon className="arrowIcon" fontSize="large" onClick={handleButtonClickRight} sx={{marginLeft: "2rem"}} />
          </Grid>
          {/* <Grid
            item
            xs={12}
            sm={12}
            lg={3}
            justifyContent="center"
            alignItems="center"
            sx={{ textAlign: "center" }}
          >
            <Button
              variant="contained"
              onClick={playIdle}
              disabled={fakeIdle || idle || fase2 || fase3 ? true : false}
              className="btnAnimation"
            >
              0
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} lg={3} sx={{ textAlign: "center" }}>
            <Button
              variant="contained"
              onClick={playFase1}
              disabled={fakeIdle || fase2 || idle ? false : true}
              className="btnAnimation"
            >
              1
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} lg={3} sx={{ textAlign: "center" }}>
            <Button
              variant="contained"
              onClick={playFase2}
              disabled={fase1 || fase3 ? false : true}
              className="btnAnimation"
            >
              2
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} lg={3} sx={{ textAlign: "center" }}>
            <Button
              variant="contained"
              onClick={playFase3}
              disabled={fase2 ? false : true}
              className="btnAnimation"
            >
              3
            </Button>
          </Grid> */}
        </Grid>
      </div>
    </div>
  );
}

export default D18075;
