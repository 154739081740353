import React, { useRef, useEffect, Suspense, useState, useMemo } from "react";
import {
  useGLTF,
  useAnimations,
  Environment,
  OrbitControls,
  Center,
} from "@react-three/drei";
import { Canvas, useFrame } from "@react-three/fiber";
import Loader from "./Loader";
import { LoopOnce, LoopPingPong } from "three";
import { Grid, Typography, Box } from "@mui/material";
import ViewCube from "./ViewCube/ViewCube";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

function ModelExplode(props, { onVerification }) {
  const group = useRef();
 
  const { nodes, materials, animations } = useGLTF("/D21056.glb");
  const { actions } = useAnimations(animations, group);
  const [animationId, setAnimationId] = useState(0);
  const [faseActive, setFaseActive] = useState(true);
  const [verified, setVerified] = useState(true);




  useEffect(() => {
    if (props.idle) {
      playAnimIdle();
    }
  }, [props.idle]);

  useEffect(() => {
    if (props.fase5) {
     // playAnimIdle();
    }
  }, [props.fase5]);

  useEffect(() => {
    if (props.fase1) {
      animationId === 0 ? playAnimFase1() : playAnimFase1Reverse();
    }
  }, [props.fase1]);

  useEffect(() => {
    if (props.fase2) {
      animationId === 1 ? playAnimFase2() : playAnimFase2Reverse();
    }
  }, [props.fase2]);

  useEffect(() => {
    if (props.fase3) {
      animationId === 2 ? playAnimFase3() : playAnimFase3Reverse();
    }
  }, [props.fase3]);

  useEffect(() => {
    if (props.fase4) {
      playAnimFase4();
    }
  }, [props.fase4]);

  useFrame(() => {
    handleVerify();
  });

  const handleVerify  = () => {
    if (actions.barraEsquerdoBaixa.isRunning() || actions.barraEsquerdoSobe.isRunning() || actions.ladoCimaEsquerdoBaixaA.isRunning()|| actions.ladoCimaEsquerdoSobe.isRunning() || actions.ladoCimaEsquerdoBaixaB.isRunning() || actions.painelAI1.isRunning() || actions.painelBI1.isRunning() || actions.painelB14.isRunning() || actions.painelA14.isRunning() ) {

      setVerified(false);
      props.onVerification(false);

      
    } else {
      setVerified(true);
      props.onVerification(true);
    } 
  };

    


  const playAnimIdle = () => {
    setAnimationId(0);
    actions.ladoDireitoSobe.stop();
    actions.ladoEsquerdoSobe.stop();
    actions.molaCimaDireitoSobe.stop();
    actions.molaCimaEsquerdoSobe.stop();
    actions.molaBaixoDireitoSobe.stop();
    actions.molaBaixoEsquerdoSobe.stop();
    actions.barraEsquerdoSobe.stop();
    actions.barraDireitoSobe.stop();
    actions.ladoDireitoBaixa.play();
    actions.ladoDireitoBaixa.clampWhenFinished = true;
    actions.ladoDireitoBaixa.setLoop(LoopOnce);
    actions.ladoEsquerdoBaixa.play();
    actions.ladoEsquerdoBaixa.clampWhenFinished = true;
    actions.ladoEsquerdoBaixa.setLoop(LoopOnce);
    actions.molaCimaDireitoBaixa.play();
    actions.molaCimaDireitoBaixa.clampWhenFinished = true;
    actions.molaCimaDireitoBaixa.setLoop(LoopOnce);
    actions.molaCimaEsquerdoBaixa.play();
    actions.molaCimaEsquerdoBaixa.clampWhenFinished = true;
    actions.molaCimaEsquerdoBaixa.setLoop(LoopOnce);
    actions.molaBaixoEsquerdoBaixa.play();
    actions.molaBaixoEsquerdoBaixa.clampWhenFinished = true;
    actions.molaBaixoEsquerdoBaixa.setLoop(LoopOnce);
    actions.molaBaixoDireitoBaixa.play();
    actions.molaBaixoDireitoBaixa.clampWhenFinished = true;
    actions.molaBaixoDireitoBaixa.setLoop(LoopOnce);
    actions.barraEsquerdoBaixa.play();
    actions.barraEsquerdoBaixa.clampWhenFinished = true;
    actions.barraEsquerdoBaixa.setLoop(LoopOnce);
    actions.barraDireitoBaixa.play();
    actions.barraDireitoBaixa.clampWhenFinished = true;
    actions.barraDireitoBaixa.setLoop(LoopOnce);


  };

  const playAnimFase1Reverse = () => {
    setAnimationId(1);

    actions.ladoCimaEsquerdoBaixaA.stop();
    for (let i = 1; i < 15; i++) {
      actions[`painelA${i}`].stop();
    }
    actions.ladoCimaEsquerdoLevantaA.play();
    actions.ladoCimaEsquerdoLevantaA.clampWhenFinished = true;
    actions.ladoCimaEsquerdoLevantaA.setLoop(LoopOnce);
    for (let i = 1; i < 15; i++) {
      actions[`painelAI${i}`].play();
      actions[`painelAI${i}`].clampWhenFinished = true;
      actions[`painelAI${i}`].setLoop(LoopOnce);
      actions[`painelAI${i}`].timeScale = 1.5;
    }

  
    setAnimationId(2);

  };

  const playAnimFase1 = () => {
    actions.ladoDireitoBaixa.stop();
    actions.ladoEsquerdoBaixa.stop();
    actions.molaCimaDireitoBaixa.stop();
    actions.molaCimaEsquerdoBaixa.stop();
    actions.molaBaixoDireitoBaixa.stop();
    actions.barraEsquerdoBaixa.stop();
    actions.barraDireitoBaixa.stop();
    actions.molaBaixoEsquerdoBaixa.stop();
    actions.ladoDireitoSobe.play();
    actions.ladoDireitoSobe.clampWhenFinished = true;
    actions.ladoDireitoSobe.setLoop(LoopOnce);
    actions.ladoEsquerdoSobe.play();
    actions.ladoEsquerdoSobe.clampWhenFinished = true;
    actions.ladoEsquerdoSobe.setLoop(LoopOnce);
    actions.molaCimaDireitoSobe.play();
    actions.molaCimaDireitoSobe.clampWhenFinished = true;
    actions.molaCimaDireitoSobe.setLoop(LoopOnce);
    actions.molaCimaEsquerdoSobe.play();
    actions.molaCimaEsquerdoSobe.clampWhenFinished = true;
    actions.molaCimaEsquerdoSobe.setLoop(LoopOnce);
    actions.molaBaixoEsquerdoSobe.play();
    actions.molaBaixoEsquerdoSobe.clampWhenFinished = true;
    actions.molaBaixoEsquerdoSobe.setLoop(LoopOnce);
    actions.molaBaixoDireitoSobe.play();
    actions.molaBaixoDireitoSobe.clampWhenFinished = true;
    actions.molaBaixoDireitoSobe.setLoop(LoopOnce);
    actions.barraEsquerdoSobe.play();
    actions.barraEsquerdoSobe.clampWhenFinished = true;
    actions.barraEsquerdoSobe.setLoop(LoopOnce);
    actions.barraDireitoSobe.play();
    actions.barraDireitoSobe.clampWhenFinished = true;
    actions.barraDireitoSobe.setLoop(LoopOnce);
  

    setAnimationId(1);
  };

  const playAnimFase2 = () => {
    actions.ladoCimaEsquerdoLevantaA.stop();
    for (let i = 1; i < 15; i++) {
      actions[`painelAI${i}`].stop();
    }
    actions.ladoCimaEsquerdoBaixaA.play();
    actions.ladoCimaEsquerdoBaixaA.clampWhenFinished = true;
    actions.ladoCimaEsquerdoBaixaA.setLoop(LoopOnce);
    actions.ladoCimaEsquerdoBaixaA.timeScale = 1.5;
    for (let i = 1; i < 15; i++) {
      actions[`painelA${i}`].play();
      actions[`painelA${i}`].clampWhenFinished = true;
      actions[`painelA${i}`].setLoop(LoopOnce);
      actions[`painelA${i}`].timeScale = 1.5;
    }
    setAnimationId(2);
  };

  const playAnimFase2Reverse = () => {
    setAnimationId(2);
    actions.ladoCimaEsquerdoBaixaB.stop();
    actions.ladoCimaEsquerdoSobe.stop();
    for (let i = 1; i < 15; i++) {
      actions[`painelB${i}`].stop();
    }
    for (let i = 1; i < 15; i++) {
      actions[`painelBI${i}`].stop();
    }
    actions.ladoCimaEsquerdoLevantaA.stop();
    for (let i = 1; i < 15; i++) {
      actions[`painelAI${i}`].stop();
    }
    actions.ladoCimaEsquerdoBaixaA.play();
    actions.ladoCimaEsquerdoBaixaA.clampWhenFinished = true;
    actions.ladoCimaEsquerdoBaixaA.setLoop(LoopOnce);
    for (let i = 1; i < 15; i++) {
      actions[`painelA${i}`].play();
      actions[`painelA${i}`].clampWhenFinished = true;
      actions[`painelA${i}`].setLoop(LoopOnce);
      actions[`painelA${i}`].timeScale = 1.5;
      
    }
    setAnimationId(2);
  

  };

  const playAnimFase3 = () => {

    actions.ladoCimaEsquerdoBaixaA.stop();
    for (let i = 1; i < 15; i++) {
      actions[`painelA${i}`].stop();
    }
    actions.ladoCimaEsquerdoLevantaA.play();
    actions.ladoCimaEsquerdoLevantaA.clampWhenFinished = true;
    actions.ladoCimaEsquerdoLevantaA.setLoop(LoopOnce);
    for (let i = 1; i < 15; i++) {
      actions[`painelAI${i}`].play();
      actions[`painelAI${i}`].clampWhenFinished = true;
      actions[`painelAI${i}`].setLoop(LoopOnce);
      actions[`painelAI${i}`].timeScale = 1.5;
    }
    setAnimationId(3);
      
  };
  const playAnimFase3Reverse = () => {
    setAnimationId(3);
    actions.ladoCimaEsquerdoBaixaB.stop();
    for (let i = 1; i < 15; i++) {
      actions[`painelB${i}`].stop();
    }
    actions.ladoCimaEsquerdoSobe.play();
    actions.ladoCimaEsquerdoSobe.clampWhenFinished = true;
    actions.ladoCimaEsquerdoSobe.setLoop(LoopOnce);
    for (let i = 1; i < 15; i++) {
      actions[`painelBI${i}`].play();
      actions[`painelBI${i}`].clampWhenFinished = true;
      actions[`painelBI${i}`].setLoop(LoopOnce);
      actions[`painelBI${i}`].timeScale = 1.5;
    }
    

  };
  const playAnimFase4 = () => {

    actions.ladoCimaEsquerdoLevantaA.stop();
    actions.ladoCimaEsquerdoSobe.stop();
    for (let i = 1; i < 15; i++) {
      actions[`painelAI${i}`].stop();
    }
    for (let i = 1; i < 15; i++) {
      actions[`painelBI${i}`].stop();
    }
    actions.ladoCimaEsquerdoBaixaB.play();
    actions.ladoCimaEsquerdoBaixaB.clampWhenFinished = true;
    actions.ladoCimaEsquerdoBaixaB.setLoop(LoopOnce);
    actions.ladoCimaEsquerdoBaixaB.timeScale = 1.2;
    for (let i = 1; i < 15; i++) {
      actions[`painelB${i}`].play();
      actions[`painelB${i}`].clampWhenFinished = true;
      actions[`painelB${i}`].setLoop(LoopOnce);
      actions[`painelB${i}`].timeScale = 1.5;
    }

      
  };


  return (


    <Center>
     
      
     <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="base" position={[0, -0.26792309, 0]}>
          <mesh
            name="120045700000_top509"
            castShadow
            receiveShadow
            geometry={nodes["120045700000_top509"].geometry}
            material={materials.mat_0}
          />
          <mesh
            name="120045700000_top509_1"
            castShadow
            receiveShadow
            geometry={nodes["120045700000_top509_1"].geometry}
            material={materials["Material.002"]}
          />
        </group>
        <mesh
          name="molaBaixoDireito"
          castShadow
          receiveShadow
          geometry={nodes.molaBaixoDireito.geometry}
          material={materials["Material.004"]}
          position={[0.72424734, -0.20217158, -0.57954466]}
          rotation={[0, 0, 1.37499537]}
        />
        <mesh
          name="molaBaixoEsquerdo"
          castShadow
          receiveShadow
          geometry={nodes.molaBaixoEsquerdo.geometry}
          material={materials["Material.004"]}
          position={[-0.72320545, -0.20184441, -0.57982463]}
          rotation={[0, 0, -1.33693533]}
        />
        <mesh
          name="painelPequeno0"
          castShadow
          receiveShadow
          geometry={nodes.painelPequeno0.geometry}
          material={materials["Material #53"]}
          position={[0.07794446, 0.32161731, 0.50624859]}
          scale={0}
        />
        <mesh
          name="painelGrande1"
          castShadow
          receiveShadow
          geometry={nodes.painelGrande1.geometry}
          material={materials["Material #54"]}
          position={[-0.05991874, 0.37738729, -0.42871201]}
          scale={0}
        />
        <mesh
          name="painelGrande2"
          castShadow
          receiveShadow
          geometry={nodes.painelGrande2.geometry}
          material={materials["Material #54"]}
          position={[-0.05991874, 0.37738729, -0.35069728]}
          scale={0}
        />
        <mesh
          name="painelGrande3"
          castShadow
          receiveShadow
          geometry={nodes.painelGrande3.geometry}
          material={materials["Material #54"]}
          position={[-0.05991874, 0.37738729, -0.26888549]}
          scale={0}
        />
        <mesh
          name="painelGrande4"
          castShadow
          receiveShadow
          geometry={nodes.painelGrande4.geometry}
          material={materials["Material #54"]}
          position={[-0.05991874, 0.37738729, -0.1960676]}
          scale={0}
        />
        <mesh
          name="painelGrande5"
          castShadow
          receiveShadow
          geometry={nodes.painelGrande5.geometry}
          material={materials["Material #54"]}
          position={[-0.05991874, 0.37738729, -0.11425582]}
          scale={0}
        />
        <mesh
          name="painelGrande6"
          castShadow
          receiveShadow
          geometry={nodes.painelGrande6.geometry}
          material={materials["Material #54"]}
          position={[-0.05991874, 0.37738729, -0.03624108]}
          scale={0}
        />
        <mesh
          name="painelGrande7"
          castShadow
          receiveShadow
          geometry={nodes.painelGrande7.geometry}
          material={materials["Material #54"]}
          position={[-0.05991874, 0.37738729, 0.0455707]}
          scale={0}
        />
        <mesh
          name="painelGrande8"
          castShadow
          receiveShadow
          geometry={nodes.painelGrande8.geometry}
          material={materials["Material #54"]}
          position={[-0.05991874, 0.37738729, 0.11630911]}
          scale={0}
        />
        <mesh
          name="painelGrande9"
          castShadow
          receiveShadow
          geometry={nodes.painelGrande9.geometry}
          material={materials["Material #54"]}
          position={[-0.05991874, 0.37738729, 0.19812089]}
          scale={0}
        />
        <mesh
          name="painelGrande10"
          castShadow
          receiveShadow
          geometry={nodes.painelGrande10.geometry}
          material={materials["Material #54"]}
          position={[-0.05991874, 0.37738729, 0.27093878]}
          scale={0}
        />
        <mesh
          name="painelGrande11"
          castShadow
          receiveShadow
          geometry={nodes.painelGrande11.geometry}
          material={materials["Material #54"]}
          position={[-0.05991874, 0.37738729, 0.35275057]}
          scale={0}
        />
        <mesh
          name="painelGrande12"
          castShadow
          receiveShadow
          geometry={nodes.painelGrande12.geometry}
          material={materials["Material #54"]}
          position={[-0.05991874, 0.37738729, 0.4307653]}
          scale={0}
        />
        <mesh
          name="painelGrande13"
          castShadow
          receiveShadow
          geometry={nodes.painelGrande13.geometry}
          material={materials["Material #54"]}
          position={[-0.05991874, 0.37738729, 0.51257706]}
          scale={0}
        />
        <mesh
          name="painelPequeno1"
          castShadow
          receiveShadow
          geometry={nodes.painelPequeno1.geometry}
          material={materials["Material #53"]}
          position={[0.07794446, 0.32345277, 0.4269428]}
          scale={0}
        />
        <mesh
          name="painelPequeno2"
          castShadow
          receiveShadow
          geometry={nodes.painelPequeno2.geometry}
          material={materials["Material #53"]}
          position={[0.07794446, 0.30965328, 0.34728917]}
          scale={0}
        />
        <mesh
          name="painelPequeno3"
          castShadow
          receiveShadow
          geometry={nodes.painelPequeno3.geometry}
          material={materials["Material #53"]}
          position={[0.07794446, 0.31031331, 0.26798341]}
          scale={0}
        />
        <mesh
          name="painelPequeno4"
          castShadow
          receiveShadow
          geometry={nodes.painelPequeno4.geometry}
          material={materials["Material #53"]}
          position={[0.07794446, 0.32469761, 0.19662702]}
          scale={0}
        />
        <mesh
          name="painelPequeno5"
          castShadow
          receiveShadow
          geometry={nodes.painelPequeno5.geometry}
          material={materials["Material #53"]}
          position={[0.07794446, 0.3164123, 0.11732127]}
          scale={0}
        />
        <mesh
          name="painelPequeno6"
          castShadow
          receiveShadow
          geometry={nodes.painelPequeno6.geometry}
          material={materials["Material #53"]}
          position={[0.07794446, 0.31052068, 0.03766766]}
          scale={0}
        />
        <mesh
          name="painelPequeno7"
          castShadow
          receiveShadow
          geometry={nodes.painelPequeno7.geometry}
          material={materials["Material #53"]}
          position={[0.07794446, 0.31946051, -0.04163811]}
          scale={0}
        />
        <mesh
          name="painelPequeno8"
          castShadow
          receiveShadow
          geometry={nodes.painelPequeno8.geometry}
          material={materials["Material #53"]}
          position={[0.07794446, 0.32241756, -0.11823996]}
          scale={0}
        />
        <mesh
          name="painelPequeno9"
          castShadow
          receiveShadow
          geometry={nodes.painelPequeno9.geometry}
          material={materials["Material #53"]}
          position={[0.07794446, 0.3150025, -0.19754575]}
          scale={0}
        />
        <mesh
          name="painelPequeno10"
          castShadow
          receiveShadow
          geometry={nodes.painelPequeno10.geometry}
          material={materials["Material #53"]}
          position={[0.07794446, 0.32524002, -0.26890212]}
          scale={0}
        />
        <mesh
          name="painelPequeno11"
          castShadow
          receiveShadow
          geometry={nodes.painelPequeno11.geometry}
          material={materials["Material #53"]}
          position={[0.07794446, 0.33943841, -0.34820789]}
          scale={0}
        />
        <mesh
          name="painelPequeno12"
          castShadow
          receiveShadow
          geometry={nodes.painelPequeno12.geometry}
          material={materials["Material #53"]}
          position={[0.07794446, 0.30842549, -0.42786148]}
          scale={0}
        />
        <mesh
          name="painelPequeno13"
          castShadow
          receiveShadow
          geometry={nodes.painelPequeno13.geometry}
          material={materials["Material #53"]}
          position={[0.07794446, 0.34044561, -0.50716722]}
          scale={0}
        />
        <group
          name="ladoDireito"
          position={[0.74325484, -0.09874205, 0]}
          rotation={[0, 0, Math.PI / 2]}
          scale={0.03203261}
        >
          <mesh
            name="Cube001"
            castShadow
            receiveShadow
            geometry={nodes.Cube001.geometry}
            material={materials.mat_0}
          />
          <mesh
            name="Cube001_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube001_1.geometry}
            material={materials["Material.002"]}
          />
          <mesh
            name="Cube001_2"
            castShadow
            receiveShadow
            geometry={nodes.Cube001_2.geometry}
            material={materials["Material.004"]}
          />
          <mesh
            name="barraDireito"
            castShadow
            receiveShadow
            geometry={nodes.barraDireito.geometry}
            material={materials["Material.001"]}
            position={[0.70007253, 4.26769447, 0.06243563]}
            scale={31.21818733}
          />
          <group
            name="molaCimaDireito"
            position={[-2.54954529, 10.54669189, -18.06915283]}
            rotation={[0, 0, -0.20280348]}
            scale={31.21818733}
          >
            <mesh
              name="120045700000_top047"
              castShadow
              receiveShadow
              geometry={nodes["120045700000_top047"].geometry}
              material={materials["Material.004"]}
            />
            <mesh
              name="120045700000_top047_1"
              castShadow
              receiveShadow
              geometry={nodes["120045700000_top047_1"].geometry}
              material={materials["Material.005"]}
            />
          </group>
        </group>
        <group
          name="ladoBaixoEsquerdo"
          position={[-0.74302167, -0.09801413, 0]}
          rotation={[0, 0, -Math.PI / 2]}
          scale={0.02570325}
        >
          <mesh
            name="Cube003"
            castShadow
            receiveShadow
            geometry={nodes.Cube003.geometry}
            material={materials.mat_0}
          />
          <mesh
            name="Cube003_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube003_1.geometry}
            material={materials["Material.004"]}
          />
          <mesh
            name="Cube003_2"
            castShadow
            receiveShadow
            geometry={nodes.Cube003_2.geometry}
            material={materials["Material.001"]}
          />
          <mesh
            name="barraEsquerdo"
            castShadow
            receiveShadow
            geometry={nodes.barraEsquerdo.geometry}
            material={materials["Material.001"]}
            position={[-0.87453777, 5.94589615, 0]}
            scale={38.90559006}
          />
          <group
            name="ladoCimaEsquerdo"
            position={[0.87121069, 11.69443703, 0]}
            rotation={[0, 0, 0.02238669]}
            scale={1.24624753}
          >
            <mesh
              name="Cube"
              castShadow
              receiveShadow
              geometry={nodes.Cube.geometry}
              material={materials.mat_0}
            />
            <mesh
              name="Cube_1"
              castShadow
              receiveShadow
              geometry={nodes.Cube_1.geometry}
              material={materials["Material.002"]}
            />
            <mesh
              name="Cube_2"
              castShadow
              receiveShadow
              geometry={nodes.Cube_2.geometry}
              material={materials["Material.001"]}
            />
            <mesh
              name="Cube_3"
              castShadow
              receiveShadow
              geometry={nodes.Cube_3.geometry}
              material={materials["Material.004"]}
            />
            <mesh
              name="botaoEsquerdo"
              castShadow
              receiveShadow
              geometry={nodes.botaoEsquerdo.geometry}
              material={materials["Material.001"]}
              position={[0.62701857, 1.59971428, 17.92956924]}
            />
          </group>
          <group
            name="molaCimaEsquerdo"
            position={[3.00009561, 13.15532398, -22.42782974]}
            rotation={[0, 0, 0.22054683]}
            scale={[38.9055748, 38.9055748, 38.90559006]}
          >
            <mesh
              name="120045700000_top7092"
              castShadow
              receiveShadow
              geometry={nodes["120045700000_top7092"].geometry}
              material={materials["Material.004"]}
            />
            <mesh
              name="120045700000_top7092_1"
              castShadow
              receiveShadow
              geometry={nodes["120045700000_top7092_1"].geometry}
              material={materials["Material.005"]}
            />
          </group>
        </group>
        <mesh
          name="painelGrande0"
          castShadow
          receiveShadow
          geometry={nodes.painelGrande0.geometry}
          material={materials["Material #54"]}
          position={[-0.05991874, 0.36186719, -0.50314665]}
          scale={0}
        />
         <ambientLight intensity={1} />
         <pointLight position={[10, 10, 10]} intensity={1} />
         <pointLight position={[10, -10, -10]} intensity={1} />
         <pointLight position={[-10, 10, 10]} intensity={1} />
         <pointLight position={[0, 0, 0]} intensity={1} />
     
      </group>
    </group>
    </Center>
  

  );
}

useGLTF.preload("/D21056.glb");

function D21056() {
  const [fakeIdle, setFakeIdle] = useState(true);
  const [idle, setIdle] = useState(false);
  const [fase1, setFase1] = useState(false);
  const [fase2, setFase2] = useState(false);
  const [fase3, setFase3] = useState(false);
  const [fase4, setFase4] = useState(false);
  const [fase, setFase] = useState(0);
  const [faseActive, setFaseActive] = useState(true);


  
  const handleVerification = (result) => {
    // Update the verificationResult state based on the result from the child
    setFaseActive(result);
    
    
  };


  const handleButtonClickRight = () => {
    // console.log(result);
     // Perform an action based on the verification result
     if (faseActive) {
     
 
      if (!fase4){
      changeFases(1);}
     } else {
       // Verification failed
       // Do something else here
      
       changeFases(0);
 
     }
   };

   const handleButtonClickLeft = () => {
    // console.log(result);
     // Perform an action based on the verification result
     if (faseActive) {
      
 
       if (!idle){
       changeFases(-1);}
       
     } else {

      changeFases(0);
 
     }
   };


  const changeFases = (direction) => {
    setFase((prevFase) => {
      const newFase = prevFase + direction;
      

      if (newFase === 0 && direction === -1) {
        setIdle(true);
        setFakeIdle(false);
        setFase1(false);
        setFase2(false);
        setFase3(false);
        setFase4(false);
   
      }
      if (newFase === 1) {
        setFase1(true);
        setFakeIdle(false);
        setIdle(false);
        setFase2(false);
        setFase3(false);
        setFase4(false);
       
 

      } else if (newFase === 2) {
        setFase1(false);
        setFase2(true);
        setIdle(false);
        setFase3(false);
        setFase4(false);

      } else if (newFase === 3) {
        setFase1(false);
        setFase2(false);
        setIdle(false);
        setFase3(true);
        setFase4(false);

      }

      else if (newFase === 4) {
        setFase1(false);
        setFase2(false);
        setIdle(false);
        setFase3(false);
        setFase4(true);
      }

      return newFase;
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100vw",
        backgroundColor: "#f8f8f8",
        overflow: "hidden",
      }}
    >
      <div className="topDiv">
        <Typography
          variant="h3"
          sx={{
            fontFamily: "Outfit, sans-serif",
            fontWeight: 700,
            fontSize: "clamp(1.5rem, 4vw, 48px)",
          }}
        >
          Contentor Automóvel
        </Typography>
        <Typography
          variant="body1"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            fontFamily: "Outfit, sans-serif",
            fontWeight: 300,
            fontSize: "clamp(.5rem, 4vw, 14px)",
          }}
        >
          <Box mr={1}>
            <svg
              id="_x30_01-3d_object"
              enableBackground="new 0 0 512 512"
              height="36"
              viewBox="0 0 512 512"
              width="40"
              fill="none"
              stroke="#000"
              strokeWidth={15}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m511.476 468.168c-.198-.451-7.724-17.164-12.541-27.868-1.652-3.67-5.966-5.305-9.636-3.655-3.671 1.652-5.307 5.966-3.656 9.635l3.471 7.711-102.629-59.241c0-3.835.002-137.083-.004-138.191-.021-2.66-1.504-5.024-3.64-6.248l-119.647-69.064v-120.862c3.497 3.992 4.892 9.467 10.559 9.467 5.864 0 9.403-6.696 5.907-11.546-18.644-25.869-18.367-26.511-21.715-27.487-2.868-.834-6.04.136-7.952 2.73l-17.84 24.757c-2.353 3.265-1.613 7.819 1.653 10.171 3.259 2.348 7.816 1.62 10.173-1.653l4.639-6.438v120.862c-128.472 74.163-120.47 69.186-121.831 71.007-2.105 2.815-1.46-8.016-1.461 142.497l-102.235 59.012 3.368-7.483c1.652-3.67.015-7.984-3.655-9.635-3.671-1.651-7.986-.014-9.637 3.655-2.682 5.961-12.36 27.455-12.54 27.867-2 4.51 1.005 9.717 5.95 10.2 32.087 3.231 30.395 3.105 31.086 3.105 3.695 0 6.863-2.8 7.242-6.554.405-4.005-2.513-7.578-6.518-7.983l-7.628-.771 101.854-58.793c126.003 72.318 119.588 70.04 123.292 70.04 2.862 0-6.975 5.154 123.291-70.04l101.897 58.818-7.379.746c-4.005.405-6.923 3.978-6.518 7.983.407 4.027 4.007 6.922 7.984 6.516 32.805-3.374 32.29-2.591 34.87-5.258 1.252-1.294 2.769-4.975 1.426-8.009zm-255.571-274.3 108.715 62.754-108.715 62.754-108.715-62.754zm-116.003 75.375 108.715 62.754v125.509l-108.716-62.754zm123.291 188.262v-125.509l108.715-62.754v125.509z" />
            </svg>
          </Box>
          Arraste o objeto para uma melhor visualização 3D.
        </Typography>
      </div>
      <div className="modelDiv">
        <Canvas camera={{ position: [15, 0, 25] }}>
          <Suspense fallback={<Loader />}>
            <OrbitControls rotateSpeed={0.7} maxDistance={30} minDistance={10}/>
            <ModelExplode
              idle={idle}
              fakeIdle={fakeIdle}
              fase1={fase1}
              fase2={fase2}
              fase3={fase3}
              fase4={fase4}
              scale={15}
              onVerification={handleVerification}
            />
           
          </Suspense>
        </Canvas>
      </div>
      <div className="btnBottomDiv">
        <Grid container justifyContent="center">
          <Grid item display="flex" justifyContent="center" alignItems="center">
        
              <ChevronLeftIcon
                className="arrowIcon"
                fontSize="large"
                onClick={handleButtonClickLeft}
                sx={{ marginRight: "2rem" }}
              />
           

            <FiberManualRecordIcon
              sx={{ color: idle || fakeIdle ? "#00629A" : "#00A8E2" }}
              fontSize={idle || fakeIdle ? "medium" : "small"}
            />
            <FiberManualRecordIcon
              sx={{ color: fase1 ? "#00629A" : "#00A8E2" }}
              fontSize={fase1 ? "medium" : "small"}
            />
            <FiberManualRecordIcon
              sx={{ color: fase2 ? "#00629A" : "#00A8E2" }}
              fontSize={fase2 ? "medium" : "small"}
            />
            <FiberManualRecordIcon
              sx={{ color: fase3 ? "#00629A" : "#00A8E2" }}
              fontSize={fase3 ? "medium" : "small"}
            />
             <FiberManualRecordIcon
              sx={{ color: fase4 ? "#00629A" : "#00A8E2" }}
              fontSize={fase4 ? "medium" : "small"}
            />

              <ChevronRightIcon
                className="arrowIcon"
                fontSize="large"
                //onClick={() => {if (!idle) {changeFases(-1);}}}
                onClick={handleButtonClickRight}
                sx={{ marginLeft: "2rem" }}
              />
            
              
            
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default D21056;
